import React, { Suspense, lazy, useState, useCallback, useEffect } from "react"
import Helmet from "react-helmet"
import { Router } from "@reach/router"
import InViewObserver from "../components/InViewObserver"

import Link from "../components/LinkWithTransitionLock";
import Page_Footer from './page_footer'
// import Scrollbar from 'smooth-scrollbar';

import { TimelineMax, TweenMax, TweenLite, Linear, TextPlugin, ThrowPropsPlugin, EasePack, CSSPlugin, ScrollToPlugin, Power1, Power2, Power4, Expo, gsap } from 'gsap';

class Page extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount () {
    
    InViewObserver()
    
  }
 
  componentWillUnmount() {

  }


  render() {

    return (
      // <>

      <div className="content_wrap">

        <div className="page_header">
          <h2 className="sub_title">台北萬華　最美的是人</h2>
          <h1 className="main_title">八坪大<a href="https://www.facebook.com/8pingspace" className="location" target="_blank">↗</a></h1>
          <h6 className="speaker">店主/歐陽如修　筆者/林立青</h6>
        </div>

        <div className="page_content">

          <section className="row_area_s project_a_muse_2_a">
            <div className="column_4">
              <div className="context">
                <p>位於萬華隆昌街的八坪大有著獨特的地理位置，巧妙地橫跨在艋舺於西門町之間，既隱身於傳統，又接近於時尚潮流。店內的空間也如同店名，只有八坪大。從去年開始手做有機小農料理前往街頭送餐，還同時供與弱勢團體送暖，且常於牆面上布置主題藝術展，店內有著各種特色漫畫，尤其以台灣本地創作者的作品為主要收藏。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_2_b">
            <div className="column_8">
              <div className="video">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/mCCQIae_Us0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_2_c">
            <div className="column_6">
              <div className="context">
                <p>八坪大的幾位創辦人各有特色，有的營運和企劃著台灣的動畫創作，有的參與花蓮東海岸石梯坪的水梯田復耕計畫，幾個創辦人都是藝術出身，他們自稱老大，店長和總監，是一個平等同時有趣的組合，創辦人陳文郁從事文創IP經紀和推廣，謝春未則是長年在動畫產業營運上努力，當天招待我們的是創辦人之一的歐陽如修，她身軀嬌小，聲音輕柔，同時她也參與過台灣好食協會，更是花蓮臨海梯田農業的重要紀錄者，隱身在北市西門町巷弄旁的她，推廣好食的方式是直接供應給身邊的人享受，來到八坪大時，她會在店門口為你煮上咖啡，送上蛋糕後，介紹牆上的每一個新銳創作者展覽，入門右手邊的牆上隨時有不同的主題，甚至延伸到地下室去，那裏有三面牆，足以讓她們對你說完一個完整的藝術家故事。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_2_d">
            <div className="column_6">
              <div className="context">
                <p>八坪大的地下室也有各種活動隨時出現，展演音樂，發表新書，攝影個展或者是地方鄉親包場群聚看投開票結果喊著凍蒜，在八坪大進駐以前，沒有人想過隆昌街的國術館離開以後會生出一個這樣的店面，當時也沒有人想過，這裡會成為往後每年社福團體在送餐時的穩定贊助清單，用的還是最上等的有機米。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_2_e">
            <div className="column_5">
              <div className="context">
                <p>在地鄉親的說法證實了八坪大在地方上的聲譽，如同店門口那些長青的翠綠盆栽般嬌嫩同時帶著生機。如修去年開始，默默的參與了萬華當地的社福團體送餐活動，她親手料理這些在地有機食材，再供應交給萬華的社工和NGO團體分發，店內的海稻米正是戲劇中「太陽的孩子」裡面的故事：一個關於開發和傳統、文化與經濟掙扎之中的美麗結晶，如今這裡的人們開始會回購如修過去在花蓮沿海梯田上的原住民自然農法 - 海稻米，這些產自花蓮的海稻米如今是店內銷售長紅的經典商品，熟客總笑鬧著說原本減肥只吃一碗，結果因為海稻米而吃了兩碗。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_2_e">
            <div className="column_5">
              <div className="context">
                <p>每當萬華到了聖誕季的時候，如修會對著進門左手邊的整面書牆包送往社區實踐協會的飯糰，海稻米做成的飯糰飄著香氣，用鐵盤送到孩子們面前，到了尾牙時刻，則是煮成了熱粥，跟著人生百味一同推到台北車站供給無家可歸的寒士暖胃。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_2_f">
            <div className="column_6">
              <div className="context">
                <h5>店名八坪大，乍聽之下很小，可是一個良善動機能做的事情卻超乎所有人所求所想。這是一個城市之中，該被看到的地景，也歡迎所有人前去，那裏只有八坪，讓你見識到的世界卻遠不只八坪。</h5>
              </div>
            </div>
          </section>

        </div>

        <div className="page_footer">
          <Link to="/project_a_muse_3" className="next_page">
            <div className="next_title">柳隅茶社</div>
            <div className="next_owner">店主/藍字鈞　筆者/林立青</div>
          </Link>
        </div>

      </div>

      // </>
    )

  }

}


export default Page

